
// Generated file. Do not edit. run "npm run generateEnvironments" to update 

// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
  production: false,
    API_URI: "https://server.flash-delivery.it/",
  
    APP_HOST: "https://manager.flash-delivery.it",
};
